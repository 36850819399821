import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import { localizeLabel } from '../../util/filtersLocalizationHelpers';

const MIN_LENGTH_FOR_LONG_WORDS_IN_ACTIVIDAD = 20;

const SectionActividadComercialMaybe = props => {
  const { options, publicData, intl } = props;

  const selectedOption =
    publicData && publicData.actividad_c ? publicData.actividad_c : null;

  if (!publicData || !selectedOption) {
    return null;
  }

  // const optionConfig = options.find(o => o.key === selectedOption);
  const actividad_c = localizeLabel(selectedOption, intl);
  return (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage
          id="ListingPage.ActComerTitle"
        />
        <span className={css.description}>
          {richText(actividad_c, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_ACTIVIDAD,
            longWordClass: css.longWord,
          })}
        </span>
      </h2>
    </div>
  );
};

export default SectionActividadComercialMaybe;