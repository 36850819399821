import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_MEDIDAS = 20;

const SectionMedidasMaybe = props => {
  const { publicData } = props

  const medida1 = publicData && publicData.medida1 ? publicData.medida1 : null;
  const medida2 = publicData && publicData.medida2 ? publicData.medida2 : null;
  const medida3 = publicData && publicData.medida3 ? publicData.medida3 : null;

  return (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage
          id="ListingPage.MedidasTitle"
        />
      </h2>
      <span className={css.description}>
        <strong> <FormattedMessage id="ListingPage.width" />: </strong> {richText(medida1, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_MEDIDAS,
          longWordClass: css.longWord,
        })} cm
        <strong> <FormattedMessage id="ListingPage.length" />: </strong> {richText(medida2, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_MEDIDAS,
          longWordClass: css.longWord,
        })} cm
        <strong> <FormattedMessage id="ListingPage.height" />: </strong> {richText(medida3, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_MEDIDAS,
          longWordClass: css.longWord,
        })} cm
      </span>
    </div>
  );
};

export default SectionMedidasMaybe;