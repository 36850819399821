import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';
import { localizeFilters } from '../../util/filtersLocalizationHelpers';

const SectionCategoriaMaybe = props => {
  const { options, publicData, intl } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.categoria ? publicData.categoria : [];
  return (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.CategoriaTitle" />
      </h2>

      <PropertyGroup
        id="categoria"
        options={localizeFilters(options, intl)}
        selectedOptions={selectedOptions}
        twoColumns={true}
      />
    </div>
  );
};

export default SectionCategoriaMaybe;
