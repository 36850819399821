import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import { localizeLabel } from '../../util/filtersLocalizationHelpers';

const MIN_LENGTH_FOR_LONG_WORDS_IN_ALTURA = 20;

const SectionAlturaMaybe = props => {
  const { options, publicData, intl } = props;

  const selectedOption =
    publicData && publicData.altura ? publicData.altura : null;

  if (!publicData || !selectedOption) {
    return null;
  }

  const altura = localizeLabel(selectedOption, intl)
  return (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage
          id="ListingPage.AlturaTitle"
        />
        <span className={css.description}>
          {richText(altura, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_ALTURA,
            longWordClass: css.longWord,
          })}
        </span>
      </h2>
    </div>
  );
};

export default SectionAlturaMaybe;