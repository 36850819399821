import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import { localizeLabel } from '../../util/filtersLocalizationHelpers';

const MIN_LENGTH_FOR_LONG_WORDS_IN_ORIENTACION = 20;

const SectionOrientacionMaybe = props => {
  const { options, publicData, intl } = props;

  const selectedOption =
    publicData && publicData.orientacion ? publicData.orientacion : null;

  if (!publicData || !selectedOption) {
    return null;
  }

  const orientacion = localizeLabel(selectedOption, intl);

  return (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage
          id="ListingPage.OrientacionTitle"
        />
        <span className={css.description}>
          {richText(orientacion, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_ORIENTACION,
            longWordClass: css.longWord,
          })}
        </span>
      </h2>
    </div>
  );
};

export default SectionOrientacionMaybe;