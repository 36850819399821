import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import { localizeLabel } from '../../util/filtersLocalizationHelpers';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DENOMINACION = 20;

const SectionDenominacionMaybe = props => {
  const { options, publicData, intl } = props;

  const selectedOption =
    publicData && publicData.denominacion ? publicData.denominacion : null;

  if (!publicData || !selectedOption) {
    return null;
  }

  const denominacion = localizeLabel(selectedOption, intl)

  return (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage
          id="ListingPage.DenominacionTitle"
        />
        <span className={css.description}>
          {richText(denominacion, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DENOMINACION,
            longWordClass: css.longWord,
          })}
        </span>
      </h2>
    </div>
  );
};

export default SectionDenominacionMaybe;